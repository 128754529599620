import React from "react";
import classes from "./Sub.module.scss";
import { useTranslation } from "react-i18next";
const Sub = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.sub}>
      <div className={classes.subBody}>
        <div className="container">
          <div className={[classes.subTitle, "font-48"].join(" ")}>
            {t("trading_four__title")}
          </div>
          <div className={classes.subSubtitle}>
            {t("trading_four__subtitle")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sub;
