import classes from "./NavBar.module.scss"
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";
import arrow from "../../assets/header/arrow.svg";
import "./NavBar.scss";

const NavBar = () => {
  const { t } = useTranslation();
  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));
  const [localA, setLocalA] = useState(false);
  const lang = Cookies.get('i18next');
  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
  }, []);
  const languages = [
    { value: "ru", label: "Ru" },
    { value: "en", label: "En" },
    { value: "es", label: "Es" },
    { value: "it", label: "It" },
    { value: "de", label: "De" },
    { value: "pl", label: "Pl" },
    { value: "cz", label: "Cz" },
  ];
  const [burgerA, setBurgerA] = useState(false)

  return (
    <div>
      <div className={burgerA ? [classes.bluebg, classes.active].join(" ") : classes.bluebg}></div>
      <header className={burgerA ? [classes.header, classes.active].join(" ") : classes.header}>

        <div className={classes.header__body}>
          <div className={[classes.container, "container"].join(" ")}>
            <div className={classes.headerrow}>
              <Link to="/" className={classes.headerrow__logo} replace ><img src="img/1/1.png" alt="" /></Link>
              <div className={classes.headerrowright}>
                <div className={burgerA ? [classes.headerrowrightnav__wrap, classes.active].join(" ") : classes.headerrowrightnav__wrap}>
                  <div className={classes.headerrowrightnav}>
                    <Link to="/trading" activeClassName={classes.active} className={classes.headerrowrightnav__item}>{t("header_item1")}</Link>
                    <Link to="/analytics" activeClassName={classes.active} className={classes.headerrowrightnav__item}>{t("header_item2")}</Link>
                    <Link to="/about" activeClassName={classes.active} className={classes.headerrowrightnav__item}>{t("header_item3")}</Link>
                    {/* <Link to="/faq" activeClassName={classes.active} className={classes.headerrowrightnav__item}>{t("header_item4")}</Link> */}
                  </div>
                </div>
                <div
                  className={classes.headerRowRightLocalization}
                  onClick={() => {
                    setLocalA(!localA);
                  }}
                >
                  <div
                    className={
                      localA
                        ? burgerA
                          ? [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                            classes.white,
                          ].join(" ")
                          : [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                          ].join(" ")
                        : burgerA
                          ? [
                            classes.headerRowRightLocalizationTitle,
                            classes.white,
                          ].join(" ")
                          : classes.headerRowRightLocalizationTitle
                    }
                  >
                    {defaultOption}
                    <img src={arrow} alt="" />
                  </div>
                  <div
                    className={
                      localA
                        ? [
                          classes.headerRowRightLocalizationItems,
                          classes.active,
                        ].join(" ")
                        : classes.headerRowRightLocalizationItems
                    }
                  >
                    {languages.map((i, index) => {
                      return (
                        <p
                          key={index}
                          onClick={() => {
                            i18n.changeLanguage(i.value);
                            setDefaultOption(i.value);
                          }}
                        >
                          {i.value}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <a
                  href={`https://user.maslgroup.one/login?lang=${lang}`}
                  className={[classes.headerrowright__logbtn, "btn-anim-dark", `${t("lang")}`].join(" ")}
                >
                  {t("header_log")}
                </a>

                <div className={burgerA ? [classes.headerrowright__burger, classes.active].join(" ") : classes.headerrowright__burger}
                  onClick={() => {
                    setBurgerA(!burgerA)
                  }}
                >
                  <span></span><span></span><span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>

  );
};

export default NavBar;
