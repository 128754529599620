import React from "react";
import classes from "./ToGrow.module.scss";
import gift from "../../../assets/AboutPage/grow_gift.svg";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const ToGrow = () => {
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  return (
    <div className="container">
      <div className={classes.toGrow}>
        <div className={classes.bg}></div>
        <div className={[classes.header, "font-48"].join(" ")}>
          {t("about_four__title")}
        </div>
        <div className={classes.buttons}>
          <div className={classes.demo}>
            <a
              href={`https://user.maslgroup.one/signup?lang=${lang}`}
              className="btn-anim-dark"
            >
              {t("about_four__btn1")}
            </a>
          </div>
          <div className={classes.bonus}>
            <a
              href={`https://user.maslgroup.one/signup?lang=${lang}`}
              className="btn-anim"
            >
              {t("about_four__btn2")}
            </a>
            <div className={classes.gift}>
              <div className={classes.text}> {t("about_four__text")}</div>
              <img src={gift} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToGrow;
