import React, { useState } from "react";
import classes from "./WhatFor.module.scss";
import image from "../../../assets/AnalyticsPage/what_for.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const WhatFor = () => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  return (
    <div className="container">
      <div className={classes.whatFor}>
        <div className={classes.col}>
          <div className={[classes.header, "font-48"].join(" ")}>
            {t("analytics_second__title")}
          </div>
          <div className={classes.text}>
            <div>{t("analytics_second__subtitle1")}</div>
            <div className={classes.par}>
              {t("analytics_second__subtitle2")}
            </div>
          </div>
          <a
            href={`https://user.maslgroup.one/signup?lang=${lang}`}
            className={[classes.btn, "btn-anim-dark"].join(" ")}
            onMouseEnter={() => {
              if (window.innerWidth > 992) setActive(true);
            }}
            onMouseLeave={() => {
              if (window.innerWidth > 992) setActive(false);
            }}
          >
            {t("trading_three__startbtn")}
          </a>
        </div>
        <div className={classes.col}>
          <img
            src={image}
            alt=""
            className={active ? classes.active : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default WhatFor;
