import React from "react";
import classes from "./MarketOverview.module.scss";
import chart from "../../../assets/AnalyticsPage/market_chart.png";
import phone_01 from "../../../assets/AnalyticsPage/market_phone_01.png";
import phone_02 from "../../../assets/AnalyticsPage/market_phone_02.png";
import together from "../../../assets/AnalyticsPage/market_together.png";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
const MarketOverview = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.marketOverview}>
        <div className={classes.left}>
          <div className={[classes.header, "font-48"].join(" ")}>
            {t("analytics_four__title")}
          </div>
          <div className={classes.text}>
            <Trans>{t("analytics_four__subtitle")}</Trans>
          </div>
        </div>
        <div className={classes.right}>
          <img className={classes.chart} src={chart} alt="" />
          <img className={classes.phone_01} src={phone_01} alt="" />
          <img className={classes.phone_02} src={phone_02} alt="" />
          <img className={classes.together} src={together} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MarketOverview;
