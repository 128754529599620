import React from "react";
import classes from "./Choose.module.scss";
import { useTranslation } from "react-i18next";

const Choose = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.payment}>
      <div className={"container"}>
        <div className={classes.payment__body}>
          <div className={[classes.payment__title, "font-48"].join(" ")}>
            {t("main_four__title")}
          </div>
        </div>
      </div>
      <div className="container">
        <div className={classes.paymentmobrow__wrap}>
          <div className={classes.paymentmobrow}>
            <div className={[classes.paymentmobrow__column, classes.visa].join(" ")}>
              <img src="img/4/1.png" alt="" />
            </div>
            <div
              className={[
                classes.paymentmobrow__column,
                classes.mastercard,
              ].join(" ")}
            >
              <img src="img/4/2.png" alt="" />
            </div>
            <div className={[classes.paymentmobrow__column, classes.maestro].join(" ")}>
              <img src="img/4/3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
