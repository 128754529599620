import React from "react";
import classes from "./WhoWeAre.module.scss";
import { useTranslation } from "react-i18next";
const WhoWeAre = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.whoWeAre}>
        <div className={[classes.header, "font-48"].join(" ")}>
          {t("about_first__title")}
        </div>
        <div className={classes.content}>
          <div className={classes.left}>{t("about_first__left")}</div>
          <div className={classes.right}>
            {/* <div className={classes.text}>{t("about_first__right1")}</div> */}
            <div className={[classes.rightHeader, "font-48"].join(" ")}>
              {t("about_first__right2")}
            </div>
            <div className={classes.rightText}>{t("about_first__right3")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
