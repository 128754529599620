import React from "react";
import classes from "./Banner.module.scss";
import pentagon from "../../../assets/AnalyticsPage/pentagon_no_shadow.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Banner = () => {
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  return (
    <div className={classes.banner}>
      <div className={[classes.cont, "container"].join(" ")}>
        <div className={classes.content}>
          <div className={[classes.header, "font-48"].join(" ")}>
            {t("analytics_first__title")} {t("analytics_first__subtitle")}
          </div>

          <div className={classes.text}>{t("analytics_first__subtitle1")}</div>
          <a
            href={`https://user.maslgroup.one/signup?lang=${lang}`}
            className={[classes.btn, "btn-anim"].join(" ")}
          >
            {t("trading_three__startbtn")}
          </a>
        </div>
        <div className={classes.footer}></div>
        <img className={classes.penta} src={pentagon} alt="" />
      </div>
    </div>
  );
};

export default Banner;
