import React from "react";
import classes from "./About.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const About = () => {
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  return (
    <div className={classes.about}>
      <div className={classes.about__body}>
        <div className={"container"}>
          <div className={classes.about__row}>
            <div className={[classes.about__title, "font-48"].join(" ")}>
              {t("main_second__title")}
            </div>
            <div className={classes.aboutright}>
              <div className={classes.aboutright__text}>
                {t("main_second__subtitle1")}
              </div>
              <div className={classes.aboutright__text}>
                {t("main_second__subtitle2")}
              </div>
              <a
                href={`https://user.maslgroup.one/signup?lang=${lang}`}
                className={[classes.aboutright__btn, "btn-anim-dark"].join(" ")}
              >
                {t("main_second__learnmore")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
