import TraidingPage from "../pages/TraidingPage/TraidingPage";
import AnalyticsPage from "../pages/AnalyticsPage/AnalyticsPage";
import AboutPage from "../pages/AboutPage/AboutPage";
import StartPage from "../pages/StartPage/StartPage";

export const RouteNames = {
    START_TRADING: '/',
    TRADING: '/trading',
    ANALYTICS: '/analytics',
    ABOUT: '/about',
};

export const routes = [
    { path: "/trading", element: TraidingPage },
    { path: RouteNames.START_TRADING, element: StartPage },
    { path: RouteNames.TRADING, text: TraidingPage },
    { path: RouteNames.ANALYTICS, element: AnalyticsPage },
    { path: RouteNames.ABOUT, element: AboutPage },
];
