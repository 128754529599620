import React from "react";
import img1 from "../../../assets/trading/3/1.svg";
import img2 from "../../../assets/trading/3/2.svg";
import img3 from "../../../assets/trading/3/3.svg";
import img4 from "../../../assets/trading/3/4.svg";
import classes from "./Security.module.scss";
import { useTranslation } from "react-i18next";
const Security = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.security}>
      <div className={classes.securityBody}>
        <div className="container">
          <div className={[classes.securityTitle, "font-48"].join(" ")}>
            {t("trading_five__title")}
          </div>
          <div className={classes.securitySubtitle}>
            {t("trading_five__subtitle")}
          </div>
          <div className={classes.securityRow}>
            <div className={classes.securityRowColumn}>
              <div className={classes.securityRowIcon}>
                <img src={img1} alt="" />
              </div>
              <div className={classes.securityRowColumnText}>
                {t("trading_five__item1")}
              </div>
            </div>
            <div className={classes.securityRowColumn}>
              <div className={classes.securityRowIcon}>
                <img src={img2} alt="" />
              </div>
              <div className={classes.securityRowColumnText}>
                {t("trading_five__item2")}
              </div>
            </div>
            <div className={classes.securityRowColumn}>
              <div className={classes.securityRowIcon}>
                <img src={img3} alt="" />
              </div>
              <div className={classes.securityRowColumnText}>
                {t("trading_five__item3")}
              </div>
            </div>
            <div className={classes.securityRowColumn}>
              <div className={classes.securityRowIcon}>
                <img src={img4} alt="" />
              </div>
              <div className={classes.securityRowColumnText}>
                {t("trading_five__item4")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
