import React, { useState } from "react";
import classes from "./Start.module.scss";
import img from "../../../assets/trading/1/1.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Start = () => {
  const [ball, setBall] = useState(false);
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  return (
    <div className={classes.trade}>
      <div
        className={
          ball
            ? [classes.tradeBody, classes.active].join(" ")
            : classes.tradeBody
        }
      >
        <div className="container">
          <div className={classes.tradeWrapper}>
            <div className={[classes.tradeTitle, "font-48"].join(" ")}>
              {t("trading_first__title")}
            </div>
            <div className={classes.tradeSubtitle}>
              {t("trading_first__subtitle")}
            </div>
            <a
              href={`https://user.maslgroup.one/signup?lang=${lang}`}
              className={[classes.tradeStart, "btn-anim"].join(" ")}
              onMouseEnter={() => {
                setBall(true);
              }}
              onMouseLeave={() => {
                setBall(false);
              }}
            >
              {t("trading_first__start")}
            </a>
            <div className={classes.tradeBall}>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
