import React from "react";
import classes from "./Today.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Today = (props) => {
  const { t } = useTranslation();
  return (
    <div className={classes.start}>
      <div
        className={
          props.active
            ? [classes.start__secondbg, classes.active].join(" ")
            : classes.start__secondbg
        }
      >
        <img src="img/5/2.png" alt="" />
      </div>
      <div className={["container", classes.wrap1].join(" ")}>
        <div className={classes.start__body}>
          <div
            className={
              props.active
                ? [classes.start__firstbg, classes.active].join(" ")
                : classes.start__firstbg
            }
          >
            <img src="img/5/1.png" alt="" />
          </div>

          <div className={classes.startrow}>
            <div className={[classes.startrow__left, "font-48"].join(" ")}>
              <Trans components={{ span: <span></span> }}>
                {t("main_five__title")}
              </Trans>
            </div>
            <div className={classes.startrowright}>
              <div className={classes.startrowright__column}>
                <div className={classes.startrowright__number}>01</div>
                <div className={classes.startrowright__text}>
                  {t("main_five__item1")}
                </div>
              </div>
              <div className={classes.startrowright__column}>
                <div className={classes.startrowright__number}>02</div>
                <div className={classes.startrowright__text}>
                  {t("main_five__item2")}
                </div>
              </div>
              <div className={classes.startrowright__column}>
                <div className={classes.startrowright__number}>03</div>
                <div className={classes.startrowright__text}>
                  {t("main_five__item3")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Today;
