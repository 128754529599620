import React, { useRef, useEffect } from "react";
import Benefits from "../../components/AboutPage/Benefits/Benefits";
import OurClients from "../../components/AboutPage/OurClients/OurClients";
import ToGrow from "../../components/AboutPage/ToGrow/ToGrow";
import WhoWeAre from "../../components/AboutPage/WhoWeAre/WhoWeAre";
import { initAnimation } from "../../mixins/animationMethods";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";

const AboutPage = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref !== null && document.documentElement.clientWidth > 1200) {
      initAnimation(ref, 3);
    }
  }, []);
  return (
    <div ref={ref}>
      <NavBar />
      <WhoWeAre />
      <div id="animBlock_0">
        <Benefits />
      </div>
      <div id="animBlock_1">
        <OurClients />
      </div>
      <div id="animBlock_2">
        <ToGrow />
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
