import React from "react";
import classes from "./Grab.module.scss";
import { useTranslation } from "react-i18next";
const Grab = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.grab}>
      <div className={classes.grabBody}>
        <div className="container">
          <div className={[classes.grabTitle, "font-48"].join(" ")}>
            {t("trading_second__title")}
          </div>
          <div className={classes.grabSubtitle}>
            {t("trading_second__subtitle")}
          </div>
          <div className={classes.grabRow}>
            <div className={classes.grabRowColumn}>
              <div className={classes.grabRowColumnTitle}>
                {t("trading_second_item1__title")}
              </div>
              <div className={classes.grabRowColumnSubtitle}>
                {t("trading_second_item1__subtitle")}
              </div>
            </div>
            <div className={classes.grabRowColumn}>
              <div className={classes.grabRowColumnTitle}>
                {t("trading_second_item2__title")}
              </div>
              <div className={classes.grabRowColumnSubtitle}>
                {t("trading_second_item2__subtitle")}
              </div>
            </div>
            <div className={classes.grabRowColumn}>
              <div className={classes.grabRowColumnTitle}>
                {t("trading_second_item3__title")}
              </div>
              <div className={classes.grabRowColumnSubtitle}>
                {t("trading_second_item3__subtitle")}
              </div>
            </div>
            <div className={classes.grabRowColumn}>
              <div className={classes.grabRowColumnTitle}>
                {t("trading_second_item4__title")}
              </div>
              <div className={classes.grabRowColumnSubtitle}>
                {t("trading_second_item4__subtitle")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grab;
