import React, { useEffect, useState } from "react";
import classes from "./Trade.module.scss";
import { Trans, useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Trade = (props) => {
  const [bgActive, setBgActive] = useState(false);
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  useEffect(() => {
    setTimeout(() => {
      setBgActive(!bgActive);
      props.setAnimeNumber(1);
      setTimeout(() => {
        props.setAnimeNumber(2);
      }, 2000);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={
        bgActive ? [classes.trade, classes.active].join(" ") : classes.trade
      }
    >
      <div className="container">
        <div
          className={
            props.ballAnimeNumber === 0
              ? [classes.ball].join(" ")
              : props.ballAnimeNumber === 1
              ? [classes.ball, classes.first_a].join(" ")
              : props.ballAnimeNumber === 2
              ? [classes.ball, classes.first_a, classes.second_a].join(" ")
              : props.ballAnimeNumber === 3
              ? [
                  classes.ball,
                  classes.first_a, 
                  classes.second_a,
                  classes.three_a,
                ].join(" ")
              : [classes.ball].join(" ")
          }
        >
          <div className={classes.ball__wrap}>
            <img src="img/2/1.png" alt="" />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.trade__body}>
            <div className={classes.trade__wrap}>
              <div
                className={[classes.trade__title, "font-48", t("lang")].join(
                  " "
                )}
              >
                <Trans components={{ span: <span></span> }}>
                  {t("main_first__title")}
                </Trans>
              </div>
              <div className={[classes.trade__subtitle, t("lang")].join(" ")}>
                {t("main_first__subtitle")}
              </div>
              {t("lang") !== "pl" && (
                <a
                  href={`https://user.maslgroup.one/signup?lang=${lang}`}
                  className={[classes.trade__startbtn, "btn-anim"].join(" ")}
                >
                  {t("btn_start")}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
