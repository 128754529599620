import React from "react";
import classes from "./Benefits.module.scss";
import icon_01 from "../../../assets/AboutPage/benefits_01.png";
import icon_02 from "../../../assets/AboutPage/benefits_02.png";
import icon_03 from "../../../assets/AboutPage/benefits_03.png";
import icon_04 from "../../../assets/AboutPage/benefits_04.png";
import icon_05 from "../../../assets/AboutPage/benefits_05.png";
import { useTranslation } from "react-i18next";
const cards = [
  {
    icon: icon_01,
    text: "about_second__item1",
  },
  {
    icon: icon_02,
    text: "about_second__item2",
  },
  {
    icon: icon_03,
    text: "about_second__item3",
  },
  {
    icon: icon_04,
    text: "about_second__item4",
  },
  {
    icon: icon_05,
    text: "about_second__item5",
  },
];

const Benefits = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.benefits}>
        <div className={[classes.header, "font-48"].join(" ")}>
          {t("about_second__title")}
        </div>
        <div className={classes.cards}>
          {cards.map((card, index) => (
            <div className={classes.card} key="index">
              <div className={classes.cardIcon}>
                <img className="no-select" src={card.icon} alt="" />
              </div>
              <div className={classes.cardText}>{t(card.text)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
