import React from "react";
import classes from "./OurClients.module.scss";
import image from "../../../assets/AboutPage/our_clients.png";
import { useTranslation } from "react-i18next";
const OurClients = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.ourClients}>
        <div className={classes.left}>
          <div className={[classes.header, "font-48"].join(" ")}>
            {t("about_three__title")}
          </div>
          <div className={classes.text}>{t("about_three__subtitle")}</div>
        </div>
        <div className={classes.right}>
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OurClients;
